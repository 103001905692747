import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AccountService} from '@core/services/account';
import {MockAccountService, MockSharedService} from '@mock/services';
import {BaseAccountService} from '@core/abstract/account.abtract';
import {LocalStorageService} from '@core/services/local-storage';
import {Mode} from '@core/enums';

const accountServiceFactory = (
  localStorageService: LocalStorageService,
  mockSharedService: MockSharedService,
  http: HttpClient
) => {
  if (environment.mode === Mode.Mock)
    return new MockAccountService(http, mockSharedService, localStorageService);
  else
    return new AccountService(http, localStorageService);

};


export const AccountServiceProvider = {
  provide: BaseAccountService,
  useFactory: accountServiceFactory,
  deps: [
    LocalStorageService,
    MockSharedService,
    HttpClient,

  ]
};
