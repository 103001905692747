export enum HttpActionResultCode {
    OK = 200,
    BAD_REQUEST = 400,
    NO_AUTHORIZATION = 401,
    PAYMENT_REQUIRED = 402,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    METHOD_NOT_ALLOWED = 405,
    REQUEST_TIMEOUT = 408,
    SERVICE_UNAVAILABLE = 503
}
