export enum GaAction {
  SUBSCRIBE_EMAIL_NEWS = 'subscribe_email_news',
  SIGN_IN = 'sign_in',
  SIGN_OUT = 'sign_out',
  SIGN_UP = 'sign_up',
  CREDIT_PURCHASE_SUCCESS = 'credit_purchase_success',
  CREDIT_PURCHASE_CANCELLED = 'credit_purchase_canceLled',
  NEWSLETTER_SUBSCRIPTION = 'newsletter_subscription',
  NEWSLETTER_UNSUBSCRIBE = 'newsletter_unsubscribe',
  SEND_CONTACT_FORM = 'send_contact_form',
  ACCOUNT_ACTIVATED = 'account_activated',
  UPLOAD_TOOL_SUBMIT_SERVICE_ORDER = 'upload_tool_submit_service_order',
  UPLOAD_TOOL_SELECTED_FILE = 'upload_tool_selected_file',
  UPLOAD_TOOL_OPENED_CHECKOUT_MODAL = 'upload_tool_opened_checkout_modal',
  UPLOAD_TOOL_CREDIT_PURCHASE_SUCCESS = 'upload_tool_credit_purchase_success',
  UPLOAD_TOOL_CREDIT_PURCHASE_NO_SUCCESS = 'upload_tool_credit_purchase_no_success',
  UPLOAD_TOOL_FILE_UPLOAD = 'upload_tool_file_upload',
  UPLOAD_TOOL_FILE_UPLOADED = 'upload_tool_file_uploaded',
  UPLOAD_TOOL_CLOSED_WITHOUT_INTERACTION = 'upload_tool_closed_without_interaction',
  UPLOAD_TOOL_CLOSED_WITH_INTERACTION = 'upload_tool_closed_with_interaction',
  UPLOAD_TOOL_OPENED = 'upload_tool_opened',
}


export enum GaCategory {
  MidiReplay = 'midi_replay',
  CustomerAcquisition = 'customer_acquisition',
  CustomerSatisfaction = 'customer_satisfaction',
  CustomerCheckout = 'customer_checkout',
}

export enum GaValue {
  Low = 1,
  Medium,
  High,
}
