import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MockSharedService} from './mock.shared.service';
import {LocalStorageService} from '@core/services/local-storage/storage.service';
import {Observable} from 'rxjs/internal/Observable';
import {of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {IMockAccount} from '@mock/model';
import {BaseAccountService} from '@core/abstract/account.abtract';
import {
  IAccount,
  IAccountDiscountCode,
  IAccountSettings,
  IContactAddress,
  ICustomerPortalSessionLink,
  IOrder,
  IServiceOrder
} from '@core/model';
import {IServerOptions} from '@core/model/serverOptions';


@Injectable({
  providedIn: 'root'
})
export class MockAccountService extends BaseAccountService {

  constructor(private http: HttpClient,
              private mockSharedService: MockSharedService,
              protected localStorageService: LocalStorageService,
  ) {
    super(localStorageService);
  }

  /**
   * Updates the account settings
   * @param settings the setting to update
   */
  updateSettings(settings: IAccountSettings): Observable<IAccountSettings> {
    const mockAccount: IMockAccount = this.getAccount() as IMockAccount;
    mockAccount.settings = settings;
    mockAccount.password = 'test';
    return this.http.put<IMockAccount>(`${this.mockSharedService.accountURL(mockAccount.customerId)}`, mockAccount).pipe(
      map(res => {
        if (res)
          return res.settings;

        throw new Error(JSON.stringify({error: 'There was an error'}));
      }),
      catchError((err) => {
        return throwError(err);
      })
    );

  }

  /**
   * Returns the accounts settings
   */
  fetchSettings(): Observable<IAccountSettings> {
    return this.http.get<IMockAccount>(`${this.mockSharedService.accountURL(this.getAccount().customerId)}`).pipe(
      map(res => {
        if (res[0])
          return res[0].settings;

        return of(this.getAccount().settings);
      }),
      catchError((err => {
        return throwError(err);
      }))
    );
  }

  /**
   * Tries to load a discount code tied to the account. If none is  found
   */
  getDiscountCode(): Observable<IAccountDiscountCode | null> {
    if (!this.localStorageService.isLoggedIn())
      return of(null);

    const account: IAccount = this.getAccount();
    if (!account)
      return of(null);

    return of(account.discountCode);
  }

  /**
   * Fetches the account from the db
   */
  fetchAccount(): Observable<IAccount | null> {
    const mockAccount: IAccount = this.getAccount();
    if (!mockAccount)
      return of(null);

    return this.mockSharedService.getAccountById(mockAccount.customerId).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  fetchOrders(): Observable<IOrder[]> {
    const account: IAccount = this.getAccount();

    if (!account)
      return of([]);

    return this.http.get<IAccount>(`${this.mockSharedService.accountURL(this.getAccount().customerId)}`).pipe(
      map(resolvedAccount => {
        return resolvedAccount.orders;
      }),
      catchError((err => {
        return throwError(err);
      }))
    );
  }

  updateBillingDetails(billingAddress: IContactAddress): Observable<IContactAddress> {
    const mockAccount: IMockAccount = this.getAccount() as IMockAccount;
    mockAccount.password = 'test';
    mockAccount.billingDetails = billingAddress;

    return this.http.put<IAccount>(`${this.mockSharedService.accountURL(this.getAccount().customerId)}`, mockAccount).pipe(
      map((account: IAccount) => {
        return account.billingDetails;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );


  }

  fetchServiceOrders(): Observable<IServiceOrder[]> {
    return undefined;
  }

  createPortalSession(): Observable<ICustomerPortalSessionLink> {
    return undefined;
  }

  getServerOptions(): Observable<IServerOptions> {
    return undefined;
  }

  deleteAccount(customerId: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

}
