/**
 * Determines the mode of the website
 */
export enum Mode {
  Local = 0,
  Mock,
  Dev,
  QA,
  Prod,
}
