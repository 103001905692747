import {Injectable, isDevMode} from '@angular/core';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {GaAction, GaValue, LocalStorageKey} from '@core/enums';
import {LocalStorageService} from '@core/services/local-storage';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private gaService: GoogleAnalyticsService, private localStorageService: LocalStorageService) {

  }

  /**
   * New page view in analytics
   * @param path
   * @param title
   * @param location
   * @param options
   */
  async pageView(path: string, title?: string, location?: string, options?: {}): Promise<void> {
    if (isDevMode())
      return;
    if (!Boolean(await this.localStorageService.getLocalStorageItem(LocalStorageKey.COOKIES_ACCEPTED)))
      return;
    this.gaService.pageView(path, title, location, options);
  }

  /**
   * new event
   * @param action
   * @param category
   * @param label
   * @param value
   * @param interaction
   */
  async event(action: GaAction, category: string, label: string, value: GaValue, interaction: boolean): Promise<void> {
    if (isDevMode())
      return;
    if (!Boolean(await this.localStorageService.getLocalStorageItem(LocalStorageKey.COOKIES_ACCEPTED)))
      return;
    this.gaService.event(action, category, label, value, interaction);
  }
}
