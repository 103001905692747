import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {BaseAccountService} from '@core/abstract/account.abtract';
import {IOrder} from '@core/model';
import {IAccountOrdersResolved} from '@core/model/HTTP/Resolver';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountPanelOrdersResolver implements Resolve<IAccountOrdersResolved> {

  constructor(private baseAccountService: BaseAccountService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IAccountOrdersResolved> {
    return this.baseAccountService.fetchOrders().pipe(
      map((orders: IOrder[]) => ({orders} as IAccountOrdersResolved)),
      catchError(err => {
        return of({
          orders: null,
          errorMessage: err
        } as IAccountOrdersResolved);
      }));
  }
}
