import {Component, Inject, isDevMode, OnDestroy, PLATFORM_ID} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {LocalStorageService} from '@core/services/local-storage';
import {BaseStoreService} from '@core/abstract/store.abstract';
import {Observable, Subscription, timer} from 'rxjs';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {OrdersService} from '@core/services/account/orders.service';
import {Meta, Title} from '@angular/platform-browser';
import {LocalStorageKey, Time} from '@core/enums';
import {SharedService} from '@core/services';
import {isPlatformBrowser} from '@angular/common';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

// load remote module


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  subscriptions$: Subscription[] = [];
  showOverlay = true;

  acceptedCookies: boolean;
  cookieConsentAvailable: boolean;
  showPromoBanner = false;

  loadingMessages: string[] = [
    'Mixing music...',
    'Tuning instruments...',
    'Setting up the stage...',
    'Gathering the band...'
  ];
  message$: Observable<string>;

  constructor(private translateService: TranslateService,
              private baseStoreService: BaseStoreService,
              private localStorageService: LocalStorageService,
              private orderService: OrdersService,
              private router: Router,
              private gaService: GoogleAnalyticsService,
              private activatedRoute: ActivatedRoute,
              private afMessaging: AngularFireMessaging,
              private meta: Meta,
              private title: Title,
              private httpClient: HttpClient,
              private sharedService: SharedService,
              @Inject(PLATFORM_ID) private platformId: object
  ) {
    if (isDevMode())
      console.log('Dev Mode active');


    if (isPlatformBrowser(this.platformId)) {
      this.acceptedCookies = this.localStorageService.getLocalStorageItem(LocalStorageKey.COOKIES_ACCEPTED);
      console.log("accepted cookies:");
      console.log(this.acceptedCookies);
      this.cookieConsentAvailable = true;
      if (this.acceptedCookies) {
        this.acceptedCookies = true;
      } else if (this.acceptedCookies === null) {
        this.cookieConsentAvailable = false;
        this.acceptedCookies = false;
      }

      this.listen();
      this.setSEO();

      // this language will be used as a fallback when a translation isn't found in the current language
      translateService.setDefaultLang('en-US');

      // the lang to use, if the lang isn't available, it will use the current loader to get them
      translateService.use('en-US');
    }

    this.subscriptions$.push(this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);

    }));

    if (isDevMode())
      console.log('App component reloaded!');

    this.subscriptions$.push(this.sharedService.showLoadingOverlay.subscribe((show: boolean) => {
      this.showOverlay = show;
    }));

    this.message$ = timer(0, 3000).pipe(
      map(i => this.loadingMessages[i % this.loadingMessages.length])
    );
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild)
      return this.getChild(activatedRoute.firstChild);
    else
      return activatedRoute;
  }

  listen() {
    if (isDevMode())
      console.log('Started listening to notifications.');
    this.subscriptions$.push(this.afMessaging.messages
      .subscribe((message) => {
        this.orderService.orderCompletedNotification.next();
      }));
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {

    if (isDevMode())
      console.log('DEBUG:  Navigation interceptor called.');
    if (!event.url)
      return;


    if (event instanceof NavigationStart)
      this.showOverlay = true;

    if (event instanceof NavigationEnd) {
      // Analytics
      let pageTitle = '';
      const rt = this.getChild(this.activatedRoute);
      this.subscriptions$.push(rt.data.subscribe(data => {
        pageTitle = data.title;
      }));

      this.gaService.pageView(event.urlAfterRedirects, pageTitle);
      if (isPlatformBrowser(this.platformId))
        window.scrollTo(0, 0);


      this.showPromoBanner = event.url.includes('software') || event.url === '/';

      this.showOverlay = false;
    }


    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel)
      this.showOverlay = false;

    if (event instanceof NavigationError)
      this.showOverlay = false;


  }

  ngOnDestroy(): void {
    this.subscriptions$.forEach(x => x.unsubscribe());
  }

  setSEO() {
    this.meta.addTags(
      [
        {name: 'description', content: 'Origin Audio landing page'},
        {name: 'author', content: 'Origin Audio LLC'},
        {name: 'keywords', content: 'Sound, Music, Machine Learning, Deep Learning, Consulting, Cloud, IT, Information Technology'},
      ]
    );
    this.title.setTitle('Home - Origin Audio LLC');
  }

  async acceptCookies(accepted: boolean) {
    if (accepted) {
      console.log('Cookies accepted');
      this.acceptedCookies = true;
      this.cookieConsentAvailable = true;
    } else {
      console.log('Cookies not accepted');
      this.removeGoogleAnalyticsScript();
      this.acceptedCookies = false;
      this.cookieConsentAvailable = true;
    }

    await this.localStorageService.saveLocalStorageItem(LocalStorageKey.COOKIES_ACCEPTED, this.acceptedCookies, 30 * Time.Day);
  }

  removeGoogleAnalyticsScript() {
    // Remove the Google Analytics script tag
    const script = document.querySelector(`script[src^='https://www.googletagmanager.com/gtag/js']`);
    console.log('Removing Google Analytics script');
    console.log(script);
    if (script)
      script.remove();

    // Clear any existing tracking data and redefine global functions
    window['dataLayer'] = [];
    window['gtag'] = ()  => {
      console.log('Google Analytics is disabled');
    };

  }

}
