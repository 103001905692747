import {Injectable} from '@angular/core';
import {IServiceRoute} from '@core/model';
import {OAServices} from '@core/enums';


@Injectable({
  providedIn: 'root'
})
export class SoftwareService {

  public serviceRoutes: IServiceRoute[] = [
    {
      name: OAServices.AutoSlicer,
      route: '/auto-slicer'
    },
    {
      name: OAServices.BatchNormalizer,
      route: '/normalizer'
    },
    {
      name: OAServices.SoundSeparator,
      route: '/stem-isolator'
    },
    {
      name: OAServices.FileConverter,
      route: '/file-converter'
    },
  ];


  constructor() {

  }
}
