import {Injectable} from '@angular/core';
import {HttpActionResultCode} from '@core/enums';

@Injectable({
  providedIn: 'root'
})
export class MockErrorService {
  constructor() {
  }


  /**
   * Used to create the mock http error response
   * @param url which is called
   * @param httpStatusCode which should be use
   * @param errorMessage provides information about the error
   * @param statusText to be displayed
   */
  createHttpErrorData(url: string, httpStatusCode: HttpActionResultCode, errorMessage: string, statusText?: string): {} {
    return {
      error: errorMessage,
      headers: null,
      status: status.toString(),
      statusText: (statusText) ? statusText : 'Warning',
      url,
    };
  }

}
