import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DragDropDirective} from '@core/directives/drag_drop.directive';
import {ReactiveFormsModule} from '@angular/forms';


@NgModule({
    declarations: [
        DragDropDirective,
    ],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
    exports: [
        DragDropDirective,
    ]
})
export class DirectivesModule {
}
