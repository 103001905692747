import {Injectable, isDevMode} from '@angular/core';
import {LocalStorageKey} from '@core/enums';
import {IAccount} from '@core/model';

class ICart {
}

/**
 * LocalStorageService provides easy functions for Access to the LocalStorage
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  /**
   * @ignore
   */
  constructor() {

  }

  /**
   * Creates a LocalStorageItem with provided Params
   * @param key the key to retrieve the stored item
   * @param data which is stored in the local storage
   * @param ttl the time to live for the storage
   *
   */
  saveLocalStorageItem(key: LocalStorageKey, data: any, ttl: number): Promise<void> {
    return new Promise((resolve) => {
      const now = new Date();

      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: JSON.stringify(data),
        expiry: now.getTime() + ttl,
      };
      localStorage.setItem(key, JSON.stringify(item));
      resolve();
    });
  }

  /**
   * Loads the element from the store with the given key
   * @param key the localStorage Key
   * @returns {T}
   */
  getLocalStorageItem<T>(key: LocalStorageKey): T {

    const itemStr = localStorage.getItem(key);


    // if the item doesn't exist, return null
    if (!itemStr)
      return null;

    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }
    return JSON.parse(item.value);
    // return JSON.parse(localStorage.getItem(key));
  }

  /**
   * Clears the LocalStorage
   */
  clearLocalStorage(): void {
    localStorage.clear();
  }

  /**
   * Removes the item at provided Key
   * @param key
   */
  removeItemFromLocalStorage(key: LocalStorageKey): void {
    localStorage.removeItem(key);
  }


  /**
   * Return the Lengtt of the LocalStorage
   * @returns {number}
   */
  getLengthOfStorage(): number {
    return localStorage.length;
  }

  /**
   * Returns whether the account is signed in or not
   */
  public isLoggedIn(): boolean {
    const account: IAccount = this.getLocalStorageItem<IAccount>(LocalStorageKey.ACCOUNT);
    return account !== null;

  }

  private tokenExpired(token: string) {
    // const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    // return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

}
