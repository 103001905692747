import {environment} from '../../../environments/environment';
import {MockAuthenticationService, MockSharedService} from '@mock/services';
import {AuthenticationService, LoggingService, SharedService} from '@core/services';
import {BaseAuthenticationService} from '@core/abstract/authentication.abtract';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from '@core/services/local-storage';
import {Router} from '@angular/router';
import {AnalyticsService} from '@core/services/analytics.service';
import {Mode} from '@core/enums';
import {UrlService} from '@core/services/urlService';


const authenticationServiceFactory = (
  http: HttpClient,
  loggingService: LoggingService,
  localStorageService: LocalStorageService,
  router: Router,
  analyticsService: AnalyticsService,
  mockSharedService: MockSharedService,
  urlService: UrlService,
) => {
  if (environment.mode === Mode.Mock)
    return new MockAuthenticationService(http, mockSharedService, localStorageService, analyticsService, router, urlService);
  else
    return new AuthenticationService(http, localStorageService, router, analyticsService, urlService);

};


export const AuthenticationServiceProvider = {
  provide: BaseAuthenticationService,
  useFactory: authenticationServiceFactory,
  deps: [
    HttpClient,
    LoggingService,
    LocalStorageService,
    Router,
    AnalyticsService,
    MockSharedService,
    UrlService
  ]
};
