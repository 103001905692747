import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {BaseAccountService} from '@core/abstract/account.abtract';
import {IAccountSettings} from '@core/model';


@Injectable({
  providedIn: 'root'
})
export class AccountPanelSettingsResolver implements Resolve<IAccountSettings> {

  constructor(private baseAccountService: BaseAccountService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IAccountSettings> {
    return this.baseAccountService.fetchSettings();
  }
}
