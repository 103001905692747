import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  /**
   * Stores the redirect url if necessary
   */
  public redirectUrl: string;

  constructor() {
  }


}
