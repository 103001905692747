import {Component, isDevMode, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(2%)'}),
        animate('500ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('1000ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ]
})
export class EmailVerificationComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  success: boolean;

  constructor(private activatedRoute: ActivatedRoute,
              private title: Title) {
    this.title.setTitle('Home - Origin Audio LLC');
  }

  ngOnInit(): void {
    this.subscriptions.push(this.activatedRoute.queryParams
      .subscribe(params => {
          if (isDevMode())
            console.log(params);
          this.success = Boolean(Number(params.success));
        }
      ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

}
