<!-- Remove the container if you want to extend the Footer to full width. -->
<!-- Footer -->
<footer class="text-center mt-4">
  <!-- Grid container -->
  <div class="container p-4">
    <!-- Section: Social media -->
    <section class="mb-4">
      <!-- Facebook -->
      <a class="btn btn-outline-light btn-floating m-1 oa-icon" href="https://www.linkedin.com/company/origin-audio/"
         target="_blank" role="button"
      ><i class="fab fa-linkedin-in"></i
      ></a>

      <!-- Twitter -->
      <a class="btn btn-outline-light btn-floating m-1 oa-icon" href="https://twitter.com/OAMusicAI" target="_blank"
         role="button"
      ><i class="fab fa-twitter"></i
      ></a>

      <!-- Google -->
      <a class="btn btn-outline-light btn-floating m-1  oa-icon" href="https://discord.gg/tEFparsvJ4" target="_blank"
         role="button"
      ><i class="fab fa-discord"></i
      ></a>

      <!-- Instagram -->
      <a class="btn btn-outline-light btn-floating m-1 oa-icon" href="https://www.instagram.com/origin_audio_llc/"
         role="button"
         target="_blank"
      ><i class="fab fa-instagram"></i
      ></a>

      <!-- Linkedin -->
      <a class="btn btn-outline-light btn-floating m-1 oa-icon" href="https://originaudio.medium.com/" target="_blank"
         role="button"
      ><i class="fab fa-medium-m"></i
      ></a>

      <!-- Github -->
      <a class="btn btn-outline-light btn-floating m-1 oa-icon"
         href="mailto:contact@originaudio.net?subject=I want to learn more about Origin Audio" role="button"
      ><i class="fas fa-envelope-square"></i
      ></a>
    </section>
    <!-- Section: Social media -->

    <!-- Section: Form -->
    <section class="" *ngIf="!submitted">
      <form [formGroup]="newsletterForm" #newsletter="ngForm" (ngSubmit)="onSubmit()">
        <!--Grid row-->
        <div class="row d-flex justify-content-center mt-5 mb-4">
          <!--Grid column-->
          <div class="col-auto">
            <div class="mat-h2">
              Sign up for our newsletter
              <span><i class="fas fa-arrow-right"></i></span>
            </div>

          </div>
          <!--Grid column-->
          <!--Grid column-->
          <div class="col-md-5 col-12">
            <!-- Email input -->
            <div class="form-outline form-white mb-4">
              <input type="email" id="emailInput" class="form-control" formControlName="email"
                     placeholder="Email address"/>

              <label class="form-label text-danger" for="emailInput"
                     *ngIf="email.invalid && (email.dirty || email.touched) && email.errors?.email || email.errors?.pattern">Please
                provide a valid email address</label>
            </div>
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-auto">
            <!-- Submit button -->
            <button type="submit" class="btn btn-outline-light mb-4" [disabled]="processing || !newsletterForm.valid"
                    (click)="onSubmit()">
              Subscribe
            </button>
          </div>
          <!--Grid column-->
        </div>
        <!--Grid row-->
      </form>
    </section>

    <section *ngIf="submitted">
      <h1>Thank you for subscribing!</h1>
    </section>
    <!-- Section: Form -->

    <!-- Section: Text -->
    <section class="mb-4" hidden>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt distinctio earum
        repellat quaerat voluptatibus placeat nam, commodi optio pariatur est quia magnam
        eum harum corrupti dicta, aliquam sequi voluptate quas.
      </p>
    </section>
    <!-- Section: Text -->

    <!-- Section: Links -->
    <section class="">
      <!--Grid row-->
      <div class="row justify-content-center">
        <!--Grid column-->
        <div class="col-lg-2 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">Use & Learn</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="/pricing" class="text-black">Pricing</a>
            </li>
            <li>
              <a routerLink="{{autoSlicer.route}}" class="text-black">{{autoSlicer.name | orderServiceNamePipe}}</a>
            </li>
            <li>
              <a routerLink="{{batchNormalizer.route}}" class="text-black">{{ batchNormalizer.name | orderServiceNamePipe }}</a>
            </li>
            <li>
              <a routerLink="{{soundSeparator.route}}" class="text-black">{{soundSeparator.name | orderServiceNamePipe}}</a>
            </li>
            <li>
              <a routerLink="{{fileConverter.route}}" class="text-black">{{fileConverter.name | orderServiceNamePipe}}</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-2 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">Education</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="/education/students" class="text-black">Students</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->


        <!--Grid column-->
        <!--<div class="col-lg-2 col-md-6 mb-4 mb-md-0" *ngIf="!account">
          <h5 class="text-uppercase">Account</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a [routerLink]="'/authentication/signin'" class="text-black">Sign In</a>
            </li>
            <li>
              <a [routerLink]="'/authentication/signup'" class="text-black">Sign Up</a>
            </li>
          </ul>
        </div> -->
        <!--Grid column-->

        <!--Grid column-->
        <!--<div class="col-lg-2 col-md-6 mb-4 mb-md-0" *ngIf="account">
          <h5 class="text-uppercase">Account</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a [routerLink]="'/account/panel'" class="text-black">Dashboard</a>
            </li>
            <li>
              <a [routerLink]="'/account/panel/information'" [queryParams]="{options: 0}" class="text-black">Change
                email</a>
            </li>
            <li>
              <a [routerLink]="'/account/panel/information'" [queryParams]="{options: 1}" class="text-black">Change
                password</a>
            </li>
          </ul>
        </div> -->
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-2 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">Legal</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a href="https://data.originaudio.net/documents/Privacy%20Policy.pdf" target="_blank" class="text-black">Privacy
                Policy</a>
            </li>
            <li>
              <a href="https://data.originaudio.net/documents/Terms%20of%20Service.pdf" target="_blank"
                 class="text-black">Terms of Service</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-2 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">Company</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a href="mailto:contact@originaudio.net" style="color: black">Contact</a>
            </li>
            <li>
              <a routerLink="about" class="text-black">About</a>
            </li>
            <li>
              <a href="https://originaudio.medium.com" target="_blank" class="text-black">Blog</a>
            </li>
            <li>
              <a routerLink="about" class="text-black">ESG</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </section>
    <!-- Section: Links -->
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
    © 2021 - {{currentYear}}
    <a class="text-black" href="https://www.originaudio.net/">Origin Audio</a>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->

