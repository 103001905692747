import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {INewsletter} from '@core/model/newsletter';
import {environment} from '../../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {AnalyticsService} from '@core/services/analytics.service';
import {GaAction, GaCategory, GaValue} from '@core/enums';

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
  constructor(private http: HttpClient, private gaService: AnalyticsService) {
  }

  /**
   * Subscribe to the newsletter
   * @param newsletter
   */
  subscribeToNewsletter(newsletter: INewsletter): Observable<INewsletter> {
    const body: INewsletter = {
      email: newsletter.email,
      firstName: newsletter.firstName && newsletter.firstName.length > 0 ? newsletter.firstName : null,
      lastName: newsletter.lastName && newsletter.lastName.length > 0 ? newsletter.lastName : null,
    };

    return this.http.post<INewsletter>(`${environment.urls.accounts.base}/${environment.urls.accounts.newsletter.subscribe}`, body).pipe(
      map(res => {
        this.gaService.event(GaAction.NEWSLETTER_SUBSCRIPTION, GaCategory.CustomerAcquisition, '', GaValue.Low, true);
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
