import {Component, Inject, isDevMode, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {IAccount, INewsletterSimplifiedForm, IServiceRoute} from '@core/model';
import {BaseAccountService} from '@core/abstract/account.abtract';
import {Subscription} from 'rxjs';
import {BaseAuthenticationService} from '@core/abstract/authentication.abtract';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../../environments/environment';
import {INewsletter} from '@core/model/newsletter';
import {NewsletterService} from '@core/services/newsletter/newsletter.service';
import {ReCaptchaService} from '@core/services/reCaptcha.service';
import {isPlatformBrowser} from '@angular/common';
import {OAServices} from '@core/enums';
import {SoftwareService} from '@core/services/software.service';

declare const grecaptcha: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  account: IAccount;
  subscriptions$: Subscription[] = [];

  newsletterForm: FormGroup;

  processing: boolean;

  currentYear = new Date().getFullYear();

  showForm: boolean;

  submitted = false;

  private ServiceRoutes: IServiceRoute[] = this.softwareService.serviceRoutes;
  autoSlicer: IServiceRoute = this.ServiceRoutes.find(x => x.name === OAServices.AutoSlicer);
  batchNormalizer: IServiceRoute = this.ServiceRoutes.find(x => x.name === OAServices.BatchNormalizer);
  soundSeparator: IServiceRoute = this.ServiceRoutes.find(x => x.name === OAServices.SoundSeparator);
  fileConverter: IServiceRoute = this.ServiceRoutes.find(x => x.name === OAServices.FileConverter);

  constructor(private baseAccountService: BaseAccountService,
              private baseAuthenticationService: BaseAuthenticationService,
              private router: Router,
              private newsletterService: NewsletterService,
              private reCaptchaService: ReCaptchaService,
              @Inject(PLATFORM_ID) private platformId: object,
              private softwareService: SoftwareService) {
    this.subscriptions$.push(this.baseAuthenticationService.userSignedIn.subscribe((account: IAccount) => {
      this.account = account;
    }));


  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.addScript().then(() => {
        this.showForm = true;
      });
      this.account = this.baseAccountService.getAccount();

    }

    this.buildNewsletterForm();
  }

  ngOnDestroy() {
    this.subscriptions$.forEach(x => x.unsubscribe());
  }


  get email() {
    return this.newsletterForm.get('email');
  }

  onSubmit() {
    if (this.newsletterForm.invalid || this.processing)
      return;

    this.processing = true;

    if (isDevMode())
      console.log('Executed newsletter subscription');

    grecaptcha.ready(() => {
      grecaptcha.execute(environment.reCaptcha.v3, {action: 'newsletter'})
        .then((token) => {
          // Verify the token on the server.
          this.subscriptions$.push(this.reCaptchaService.isValidRequest(token).subscribe(() => {
            this.subscriptions$.push(this.newsletterService.subscribeToNewsletter(this.newsletterForm.value)
              .subscribe((newsletter: INewsletter) => {
                this.processing = false;
                this.submitted = true;
              }, error => {
                this.processing = false;
                this.submitted = true;
              }));
          }, error => {
            this.processing = false;
            this.submitted = true;
          }));
        });
    });
  }

  buildNewsletterForm(): void {
    this.newsletterForm = new FormGroup<INewsletterSimplifiedForm>({
      email: new FormControl<string>('',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
        ]),
    });
  }


  /**
   * Adds the google recaptcha script
   */
  addScript() {

    return new Promise((resolve, reject) => {
      if (isPlatformBrowser(this.platformId)) {
        let scriptTagElement;
        scriptTagElement = document.createElement('script');
        scriptTagElement.src = `https://www.google.com/recaptcha/api.js?render=${environment.reCaptcha.v3}`;
        scriptTagElement.onload = resolve;
        document.body.appendChild(scriptTagElement);
      }
    });
  }
}
