export const environment = {
  production: false,
  mode: 3,
  stripe: {
    pk: 'pk_test_51JPFqlEATrsmu0xj4KgH6naRacMf5wChAs1TKYNGFGBzHgb4KVye1g8cM31tcNHGQ463yB0niQ7fbj1ETio4tpTl00TSSiCdyy',
  },
  urls: {
    podcasts: {
      base: 'https://qa-oa-api-management.azure-api.net/podcasts',
      shows: 'shows',
      episodes: 'episodes',
      episodeReUpload: 'upload'
    },
    store: {
      base: 'https://qa.api.originaudio.net/store',
      checkout: {
        base: 'checkout',
        product: 'checkout/product',
        paymentIntent: 'checkout/payment',
        promoCode: 'checkout/promoCode'
      },
      holidays: 'holidays',
      portal: 'portal',
      serviceOrders: {
        base: 'serviceorders',
      },
      options: 'serveroptions',
      subscriptions: {
        base: 'subscriptions',
      }
    },
    accounts: {
      base: 'https://qa.api.originaudio.net/accounts',
      authentication: {
        signIn: 'signin',
        signUp: 'signup'
      },
      accounts: 'accounts',
      contact: 'contact',
      recovery: {
        activationMail: 'recovery/activationemail ',
        password: 'recovery/password'
      },
      options: 'serveroptions',
      newsletter: {
        subscribe: 'newsletter/subscribe'
      },
      reCaptcha: 'siteverify'
    },
    career: {
      base: 'https://qa-oa-api-management.azure-api.net/career',
    }
  },
  analyticsKey: '',
  reCaptcha: {
    v2: '6LeeZl4cAAAAAC8g0S9zAELyTKzgnjUcPHqymGX2',
    v3: '6Ld5kPQeAAAAAAlf1UOcgvYU-Wga9myWuxt7t7pD',
  },
  prices: {
    plans: {
      monthly: {
        basic: 'price_1JwKQtEATrsmu0xjJXXhtmYS',
        standard: 'price_1JwKRHEATrsmu0xj9P2KI4tD',
        premium: 'price_1JwKZWEATrsmu0xjqbpxG82Q'
      },
      yearly: {
        basic: 'price_1JwKQtEATrsmu0xjJXXhtmYS',
        standard: 'price_1JwKRHEATrsmu0xj9P2KI4tD',
        premium: 'price_1JwKZWEATrsmu0xjqbpxG82Q'
      }
    },
    products: {
      onDemandCredits: {
        small: 'price_1KIzGnEATrsmu0xjKrpTYzXl', // 400 Credits
        medium: 'price_1KIzIHEATrsmu0xjmdGgLgYC', // 1000 Credits
        large: 'price_1KF7llEATrsmu0xjigITs03P' // 2250 Credits
      }
    }
  },
  firebase: {
    apiKey: 'AIzaSyAw5WEAaja3xlAv4mHOZCr8eug3xrNuiug',
    authDomain: 'website-d65af.firebaseapp.com',
    projectId: 'website-d65af',
    storageBucket: 'website-d65af.appspot.com',
    messagingSenderId: '522117510861',
    appId: '1:522117510861:web:69068dcb9841c8dfc91b84',
    measurementId: 'G-TXJ8RKL50D'
  }
};
