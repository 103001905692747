import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MockSharedService} from './mock.shared.service';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {BaseAuthenticationService} from '@core/abstract/authentication.abtract';
import {LocalStorageService} from '@core/services/local-storage';
import {AnalyticsService} from '@core/services/analytics.service';
import {Router} from '@angular/router';
import {ISignInData, ISignUpData} from '@core/model/HTTP/Requests';
import {IAccount} from '@core/model';
import {LocalStorageKey, Time} from '@core/enums';
import {isDevMode} from '@angular/core';
import {UrlService} from '@core/services/urlService';


export class MockAuthenticationService extends BaseAuthenticationService {
  constructor(private http: HttpClient,
              private mockSharedService: MockSharedService,
              protected localStorageService: LocalStorageService,
              protected analyticsService: AnalyticsService,
              protected router: Router,
              protected urlService: UrlService) {
    super(localStorageService, router, analyticsService, urlService);
  }

  /**
   * Handles the sign in mock
   * @param signInData
   */
  public signIn(signInData: ISignInData): Observable<IAccount> {

    return this.mockSharedService.getAccountByEmail(signInData.email).pipe(
      mergeMap(account =>
        this.http.post<any>(`${environment.urls.accounts.base}/signin`, signInData).pipe(
          map((session: any) => {
            const newAccount: IAccount = {
              customerId: '1',
              firstName: account.firstName,
              lastName: account.lastName,
              email: account.email,
              joinDate: account.joinDate,
              language: account.language,
              lastLogin: account.lastLogin,
              emailVerified: account.emailVerified,
              discordId: account.discordId,
              access: account.access,
              settings: account.settings,
              balance: account.balance,
              session: session.accessToken,
              billingDetails: account.billingDetails,
              orders: account.orders,
              discountCode: account.discountCode,
              student: account.student,
              subscriptionCredits: 1000,
              serviceOrders: [],
              subscriptions: [],
              firstSignIn: true,
              hasFireBaseToken:  false,
              trialAllowed: true,
              referralLink: 'nothing'
            };

            this.localStorageService.saveLocalStorageItem(LocalStorageKey.ACCOUNT, newAccount, 1 * Time.Hour);
            this.localStorageService.saveLocalStorageItem(LocalStorageKey.SESSION, newAccount.session, 1 * Time.Hour);
            return newAccount;
          }),
        ),
      )
    );
  }

  signUp(signUpData: ISignUpData): Observable<IAccount> {
    if (isDevMode())
      console.log(signUpData);

    return this.http.post<any>(`${environment.urls.accounts.base}`, signUpData).pipe(
      catchError((err) => {
        console.log(err);
        return throwError(err);
      }));
  }
}
