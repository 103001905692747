import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {IStepperParameters} from '@core/model';

@Injectable({
  providedIn: 'root'
})
export class UploadToolService {

  /**
   * Being fired when the file select button is being pressed in the stepper
   */
  selectButtonClicked: Subject<void> = new Subject<void>();


  /** Is being fired after parameters for a the upload steppers are entered */
  public serviceParametersChanged: Subject<IStepperParameters> = new Subject<IStepperParameters>();

}
