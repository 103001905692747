import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IEpisode, IEpisodeReUpload} from '@core/model/podcasts';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from '../../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PodcastsEpisodeService {
  constructor(private http: HttpClient) {
  }

  getEpisode(showId: string, episodeId: string): Observable<IEpisode> {
    return this.http.get<IEpisode>(`${environment.urls.podcasts.base}/${environment.urls.podcasts.shows}/${showId}/${environment.urls.podcasts.episodes}/${episodeId}`).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  /**
   * Creates a new episode for the given show
   * @param showId
   * @param episode
   * @return The newly created episode or an error
   */
  createNewEpisode(showId: string, episode: IEpisode): Observable<IEpisode> {
    return this.http.post<IEpisode>(`${environment.urls.podcasts.base}/${environment.urls.podcasts.shows}/${showId}/${environment.urls.podcasts.episodes}`, episode).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  /**
   * Updates the given episode
   * @param showId
   * @param episode
   */
  updateEpisode(showId: string, episode: IEpisode): Observable<IEpisode> {
    return this.http.put<IEpisode>(`${environment.urls.podcasts.base}/${environment.urls.podcasts.shows}/${showId}/${environment.urls.podcasts.episodes}/${episode.id}`, episode).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  /**
   * Deletes the given episode
   * @param showId
   * @param episodeId
   */
  deleteEpisode(showId: string, episodeId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.urls.podcasts.base}/${environment.urls.podcasts.shows}/${showId}/${environment.urls.podcasts.episodes}/${episodeId}`).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  /**
   * Request the reupload for an episode
   * @param showId
   * @param episodeId
   * @param body
   */
  reUploadEpisode(showId: string, episodeId: string, body: IEpisodeReUpload): Observable<IEpisodeReUpload> {
    return this.http.post<IEpisodeReUpload>(`${environment.urls.podcasts.base}/${environment.urls.podcasts.shows}/${showId}/${environment.urls.podcasts.episodes}/${episodeId}/upload`, body).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
