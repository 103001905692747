import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {IMockAccount} from '@mock/model';
import {MockErrorService} from '@mock/services/mock.error.service';
import {HttpActionResultCode} from '@core/enums';
import {IAccount} from '@core/model';
import {IAdminAccountLookup} from '@core/model/admin';

@Injectable({
  providedIn: 'root',
})
export class MockSharedService {

  constructor(private http: HttpClient, private mockErrorService: MockErrorService) {

  }

  getAdminAccountByEmail(email: string): Observable<IAdminAccountLookup> {
    const url = `${environment.urls.accounts.base}?email=${email}`;
    return this.http.get<IMockAccount>(url).pipe(
      map(res => {
        if (res[0])
          return res[0];

        throw new HttpErrorResponse(this.mockErrorService.createHttpErrorData(url, HttpActionResultCode.NOT_FOUND,
          'Account could not be found!'));
      })
    );
  }


  /**
   * Returns an account from the database
   * @param email the customers email address
   */
  getAccountByEmail(email: string): Observable<IAccount> {
    const url = `${environment.urls.accounts.base}?email=${email}`;
    return this.http.get<IMockAccount>(url).pipe(
      map(res => {
        if (res[0])
          return res[0];

        throw new HttpErrorResponse(this.mockErrorService.createHttpErrorData(url, HttpActionResultCode.NOT_FOUND,
          'Account could not be found!'));
      })
    );
  }

  /**
   * Returns the account response
   * @param customerId of the account
   */
  getAccountById(customerId: string): Observable<IAccount> {
    const url = `${environment.urls.accounts.base}?id=${customerId}`;
    return this.http.get<IMockAccount>(url).pipe(
      map(res => {
        if (res[0])
          return res[0];

        throw new HttpErrorResponse(this.mockErrorService.createHttpErrorData(url, HttpActionResultCode.NOT_FOUND,
          'Account could not be found'));
      })
    );
  }


  /**
   * Builds the accountURL for further usage
   */
  public accountURL(customerId: string): string {
    return `${environment.urls.accounts.base}/${customerId}`;
  }

}

