<nav class="navbar navbar-expand-lg navbar-light sticky-top oa-navbar oa-nav-transition navbar-text py-4" id="navbar">
  <div class="container">
    <div class="mat-h1 mb-0">
      <a routerLink="">
        <img ngSrc="/assets/images/logo/logowhitebg.jpg" alt="Origin Audio Logo" width="100" height="50">
      </a>
    </div>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            id="hamburger-menu" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon" (click)="closeMenu()"></span>
    </button>
    <div class="collapse navbar-collapse px-0" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto  mb-2 mb-lg-0">

        <li class="nav-item px-3">
          <div class="mat-h1 my-auto">
            <a class="nav-link" routerLink="pricing">
              Pricing
            </a>
          </div>
        </li>
        <li class="nav-item px-3">
          <div class="mat-h1 my-auto">
            <a class="nav-link" routerLink="about">
              About
            </a>
          </div>
        </li>
        <li class="nav-item px-3">
          <div class="mat-h1 my-auto">
            <a class="nav-link" href="https://originaudio.medium.com/" target="_blank">
              Blog
            </a>
          </div>
        </li>
      </ul>
      <!-- <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
   <li class="nav-item px-3" *ngIf="!account">
     <div class="mat-h1 my-auto">
       <a class="nav-link" routerLink="authentication/signin">
         <i class="fas fa-sign-in-alt"></i>
       </a>
     </div>
   </li>
<li class="nav-item px-3" *ngIf="account">
     <div class="mat-h1 my-auto">
       <a class="nav-link" routerLink="account/panel">
         <i class="far fa-user"></i>
       </a>
     </div>
   </li>
   <li class="nav-item px-3" *ngIf="account" id=signOut>
     <div class="mat-h1 my-auto">
       <a class="nav-link pl-0" (click)="signOut()" style="cursor: pointer;">
         <i class="fas fa-sign-out-alt"></i>
       </a>
     </div>
   </li>
      </ul>-->
    </div>
  </div>
</nav>

