import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from '../../../environments/environment';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(private http: HttpClient, private afMessaging: AngularFireMessaging) {
  }

  /**
   * Requests permission and stores the token when a customer accepts
   */
  requestPermission(): Observable<string> {
    return this.afMessaging.requestToken.pipe(
      mergeMap((token: string) => {
        if (!token)
          return '';

        return this.storeToken(token).pipe(
          map(() => {
            return token;
          }),
          catchError(err => {
            return throwError(err);
          }));

      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  storeToken(token: string): Observable<any> {
    return this.http.post(`${environment.urls.accounts.base}/firebase/notifications/${token}`, null).pipe(
      catchError((err) => {
        return throwError(err);
      }));
  }

  deleteToken(): Observable<any> {
    return this.http.delete(`${environment.urls.accounts.base}/firebase/notifications/0`).pipe(
      catchError(err => {
        return throwError(err);
      }));
  }

}
