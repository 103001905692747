import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {BaseStoreService} from '@core/abstract/store.abstract';
import {IActiveHolidayResolved} from '@core/model/HTTP/Resolver/store';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {IHoliday} from '@core/model';

@Injectable({
  providedIn: 'root'
})
export class ActiveHolidayResolver implements Resolve<IActiveHolidayResolved> {

  constructor(private baseStoreService: BaseStoreService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IActiveHolidayResolved> {
    return this.baseStoreService.getActiveHoliday().pipe(
      map((holiday: IHoliday) => ({holiday} as IActiveHolidayResolved)),
      catchError(err => {
        return of({
          holiday: null,
          error: err
        } as IActiveHolidayResolved);
      })
    );
  }
}
