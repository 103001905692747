import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {

  /* Is triggered when a new notification is received */
  receivedNotification: Subject<string> = new Subject<string>();

  constructor(private translateService: TranslateService) {
  }


}
