import {Injectable, isDevMode} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {IServerOptions} from '@core/model/serverOptions';
import {IServerOptionsResolved} from '@core/model/HTTP/Resolver/store';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {BaseAccountService} from '@core/abstract/account.abtract';

@Injectable({
  providedIn: 'root'
})
export class AccountServerOptionsResolver implements Resolve<IServerOptionsResolved> {

  constructor(private baseAccountService: BaseAccountService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IServerOptionsResolved> {
    return this.baseAccountService.getServerOptions().pipe(
      map((serverOptions: IServerOptions) => ({serverOptions} as IServerOptionsResolved)),
      catchError(err => {
        if(isDevMode())
          console.error(err);
        return of({
          serverOptions: {
            maintenance: true
          },
          error: err
        } as IServerOptionsResolved);
      })
    );
  }
}
