export enum OrderStatus {
  NotStarted = 0,
  Created,
  AwaitingPayment,
  Paid ,
  Processing,
  Uploading ,
  Queued ,
  Complete ,
  Error ,
  Cancelled ,
}

export enum OrderItemStatus {
  AwaitingUpload = 0,
  Received,
  Completed,
  Error ,
  Canceled,
}
