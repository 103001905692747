import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LocalStorageService} from '@core/services/local-storage';
import {BaseAuthenticationService} from '@core/abstract/authentication.abtract';
import {UrlService} from '@core/services/urlService';


@Injectable({
  providedIn: 'root'
})
export class SignedInGuard implements CanActivate {

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private baseAuthenticationService: BaseAuthenticationService,
    private urlService: UrlService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.localStorageService.isLoggedIn())
      return true;

    this.baseAuthenticationService.userSignedIn.next(null);
    // Store the attempted URL for redirecting
    this.urlService.redirectUrl = state.url;
    this.router.navigate(['/authentication/signin']).then();
    return false;
  }

}
