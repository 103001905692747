<div class="container">
  <div class="row pt-5">
    <div
      class="col-sm-12 col-md-6 mt-5 ml-3 mr-3 ml-md-0 mr-md-0 col-md-6 pt-5 pb-5 p-md-5 mx-auto text-center oa-background-round">
      <div *ngIf="success">
        <div class="mat-display-3 mb-5">Your email is verified!</div>
        <i class="fas fa-check fa-6x" style="color: green"></i>
        <div class="h2 mb-0">Thank you</div>
      </div>

      <div *ngIf="!success">
        <div class="mat-display-3 mb-5">We are sorry!</div>
        <div class="mat-h1 mb-0">There was an issue verifying your email!</div>
        <br>
        <div class="mat-h1 mb-0">Please try again or contact us</div>
        <button class="btn btn-outline-light btn-xl oa-btn-primary mt-3"><a href="mailto:support@originaudio.net"
                                                                            style="text-decoration: none; color: white">Support</a>
        </button>
      </div>
    </div>
  </div>
</div>


