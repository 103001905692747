export enum LocalStorageKey {
  ACCOUNT = 'account',
  SESSION = 'session',
  STORE_CART = 'store_cart',
  SELECTED_SUBSCRIPTION_PLAN = 'selected_subscription_plan',
  PODCAST_ACCESS = 'podcast_access',
  COOKIES_ACCEPTED = 'cookies_accepted',

  ACCOUNT_SERVER_OPTIONS = 'account_server_options',
  STORE_SERVER_OPTIONS = 'store_server_options',
  DISPLAY_NEW_DESIGN = 'display_new_design',
  ON_DEMAND_CREDITS = 'on_demand_credits',
}
