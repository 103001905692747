import {Injectable} from '@angular/core';
import {ILanguage} from '@core/model';

@Injectable({
  providedIn: 'root'
})

export class LanguageService {
  public languages: ILanguage[] = [
    {id: 1, name: 'English'},
  ];

  constructor() {
  }

  /**
   * Returns the language from the languages dict
   * @param languageId provided by the caller
   */
  findLanguage(languageId: number): ILanguage | null {
    return this.languages.find(x => x.id === languageId);
  }

}
