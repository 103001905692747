import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {
  CheckoutFile,
  ICheckoutSessionLink,
  ICredits,
  IEndTrialResponse,
  IHoliday,
  IPaymentDialogResponse,
  IPaymentIntent,
  IPromoCode,
  IServiceCheckoutResponse,
  ISubscriptionPlan,
  IUploadedOrderDto,
} from '@core/model';
import {IServerOptions} from '@core/model/serverOptions';
import {environment} from '../../../environments/environment';
import {OrderStatus} from '@core/enums';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseStoreService {
  public prices: Map<string, ICredits> = new Map<string, ICredits>([
    [environment.prices.products.onDemandCredits.small, {
      priceId: environment.prices.products.onDemandCredits.small,
      credits: 400,
      price: 3,
      name: 'Small',
    }],
    [environment.prices.products.onDemandCredits.medium, {
      priceId: environment.prices.products.onDemandCredits.medium,
      credits: 1000,
      price: 7,
      name: 'Medium',
    }],
    [environment.prices.products.onDemandCredits.large, {
      priceId: environment.prices.products.onDemandCredits.large,
      credits: 2250,
      price: 15,
      name: 'Large',
    }],
  ]);

  public monthlySubscriptionPlans: Map<string, ISubscriptionPlan> = new Map<string, ISubscriptionPlan>([
    [environment.prices.plans.monthly.basic, {
      priceId: environment.prices.plans.monthly.basic,
      credits: 750,
      price: 5,
      name: 'Basic',
      accessDays: 1,
    }],
    [environment.prices.plans.monthly.standard, {
      priceId: environment.prices.plans.monthly.standard,
      credits: 2000,
      price: 8,
      name: 'Standard',
      accessDays: 3,
    }],
    [environment.prices.plans.monthly.premium, {
      priceId: environment.prices.plans.monthly.premium,
      credits: 3500,
      price: 12,
      name: 'Pro',
      accessDays: 5,
    }],
  ]);

  public yearlySubscriptionPlans: Map<string, ISubscriptionPlan> = new Map<string, ISubscriptionPlan>([
    [environment.prices.plans.yearly.basic, {
      priceId: environment.prices.plans.yearly.basic,
      credits: 12000,
      price: 5,
      name: 'Basic',
      accessDays: 1,
    }],
    [environment.prices.plans.yearly.standard, {
      priceId: environment.prices.plans.yearly.standard,
      credits: 27000,
      price: 8,
      name: 'Standard',
      accessDays: 3,
    }],
    [environment.prices.plans.yearly.premium, {
      priceId: environment.prices.plans.yearly.premium,
      credits: 54000,
      price: 12,
      name: 'Pro',
      accessDays: 5,
    }],
  ]);

  // Subscriptions start

  /** Being fired when the subscription tier is being changed. Payload is the new price id */
  public monthlyToYearlyPlanSwitch: Subject<boolean> = new Subject<boolean>();

  /**
   * Fired when a service order is updated
   */
  public serviceOrderUpdated: BehaviorSubject<number> = new BehaviorSubject<number>(OrderStatus.NotStarted);

  // Subscriptions end

  protected constructor() {
  }

  /**
   * Creates a new checkout session for a subscription
   * @param priceId
   */
  abstract createSubscriptionCheckoutSession(priceId: string): Observable<ICheckoutSessionLink>;

  /**
   * Creates a checkout session for a product
   * @param priceId the price id to buy
   */
  abstract createProductCheckoutSession(priceId: string): Observable<ICheckoutSessionLink>;

  /**
   * Creates a checkout session for a service
   * @param email The email of the customer
   * @param guestAccount Is true if customer did not check the create account checkbox
   * @param serviceName The service name being used
   * @param files The files registered for the service
   * @param parameters the parameters for the url
   * @param paymentIntentId the payment intent id the order is tied to
   * @return A service checkout response, including different params to upload the data etc.
   */
  abstract createServiceCheckoutSession(email: string, guestAccount: boolean, serviceName: string, files: CheckoutFile[],
                                        parameters: Map<string, string>, paymentIntentId?: string):
    Observable<IServiceCheckoutResponse>;

  /**
   * Requests all server options
   */
  abstract getServerOptions(): Observable<IServerOptions>;

  /**
   * Reports that the file upload successfully finished
   */
  abstract reportFileUpload(orderId: string): Observable<IUploadedOrderDto>;

  /**
   * Ends a trial immediately. Bills the customer
   */
  abstract endSubscriptionTrial(subscriptionId: string): Observable<IEndTrialResponse>;

  abstract createPaymentIntent(email: string, requiredCredits: number, promoCode?: string): Observable<IPaymentIntent>;

  /**
   * Gets the promo code with the given code, if it exists
   */
  abstract getPromoCode(promoCode: string): Observable<IPromoCode>;

  /**
   * Returns the currently active holiday or none if there is none
   */
  abstract getActiveHoliday(): Observable<IHoliday>;

}


