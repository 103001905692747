import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, mergeMap} from 'rxjs/operators';
import {of, throwError} from 'rxjs';
import {IMockAccount} from '@mock/model';
import {BaseStoreService} from '@core/abstract/store.abstract';
import {MockErrorService} from '@mock/services/mock.error.service';
import {Injectable} from '@angular/core';
import {
  CheckoutFile,
  IAccount,
  ICart,
  ICheckoutSessionLink,
  IEndTrialResponse,
  IHoliday,
  IOrder,
  IOrderItem,
  IPaymentIntent,
  IProduct, IPromoCode,
  IServiceCheckoutResponse,
  IServiceFile,
  IUploadedOrderDto,
} from '@core/model';
import {LocalStorageKey, OrderStatus} from '@core/enums';
import {environment} from '../../../environments/environment';
import {MockSharedService} from '@mock/services/mock.shared.service';
import {LocalStorageService} from '@core/services/local-storage';
import {v4 as uuidv4} from 'uuid';
import * as dayjs from 'dayjs';
import {IServerOptions} from '@core/model/serverOptions';

@Injectable({
  providedIn: 'root'
})
export class MockStoreService extends BaseStoreService {




  constructor(private http: HttpClient,
              private mockErrorService: MockErrorService,
              private mockSharedService: MockSharedService,
              private localStorageService: LocalStorageService) {
    super();
  }

  getStoreProducts(): Observable<IProduct[]> {
    return this.http.get<IProduct[]>(`${environment.urls.store.base}`).pipe(
      catchError((err: any) => {
        console.log('Error in getStore Items');
        console.log(err.error);
        return of([]);
      }));
  }


  createStoreOrder(cart: ICart, accountId: number): Observable<IOrder> {
    throw new Error('Method not implemented.');
  }

  createMidiReplayOrder(cart: ICart, customerId: string): Observable<IOrder> {

    return this.mockSharedService.getAccountById(customerId).pipe(
      mergeMap(account => {
          const order: IOrder = {
            id: uuidv4(),
            items: null, // this.convertFilesToOrderItem(cart.files, 1, 'Midi Replay'),
            purchaseDate: dayjs().unix(),
            refund: null,
            total: 0, // cart.priceTotal,
            billingAddress: null, // cart.billingAddress,
            shippingAddress: null, // cart.shippingAddress,
            status: OrderStatus.Paid,
          };

          account.orders.push(order);

          const mockAccount = this.localStorageService.getLocalStorageItem<IAccount>(LocalStorageKey.ACCOUNT) as IMockAccount;
          mockAccount.orders = account.orders;
          mockAccount.password = 'test';

          return this.http.put<IMockAccount>(`${this.mockSharedService.accountURL(mockAccount.customerId)}`, mockAccount).pipe(
            mergeMap(res => {
              if (res)
                return res.orders;

              throw new Error(JSON.stringify({error: 'There was an error'}));
            }),
            catchError((err) => {
              return throwError(err);
            })
          );
        }
      ));

  }


  private convertFilesToOrderItem(files: IServiceFile[], serviceOrProductId: string, serviceOrProductName: string): IOrderItem[] {
    const orderItems: IOrderItem[] = [];

    for (const file of files)
      orderItems.push({
        productId: serviceOrProductId,
        price: file.price,
        amount: 1,
      } as IOrderItem);

    return orderItems;

  }

  cancelOrder(paymentOrderId: string): Observable<void> {
    return undefined;
  }

  createSubscriptionCheckoutSession(priceId: string): Observable<ICheckoutSessionLink> {
    return undefined;
  }

  getMaintenanceMode(): Observable<boolean> {
    return undefined;
  }

  getServerOptions(): Observable<IServerOptions> {
    return undefined;
  }

  reportFileUpload(orderId: string): Observable<IUploadedOrderDto> {
    return undefined;
  }

  endSubscriptionTrial(subscriptionId: string): Observable<IEndTrialResponse> {
    return undefined;
  }

  createProductCheckoutSession(priceId: string): Observable<ICheckoutSessionLink> {
    return undefined;
  }

  createServiceCheckoutSession(email: string, createAccount: boolean, serviceName: string, files: CheckoutFile[],
                               parameters: Map<string, string>):
    Observable<IServiceCheckoutResponse> {
    return undefined;
  }

  createPaymentIntent(email: string, requiredCredits: number, promoCode?: string): Observable<IPaymentIntent> {
    return undefined;
  }

  getPromoCode(promoCode: string): Observable<IPromoCode> {
    throw new Error('Method not implemented.');
  }

  getActiveHoliday(): Observable<IHoliday> {
    throw new Error('Method not implemented.');
  }

}
