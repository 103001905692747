<!--<div class="container">
  <div class="row h-15  justify-content-center">
    <div class="col-12">
      <h1 class="text-center" style="color: darkred !important;">Use code OA50 to get 50% off your order!</h1>
      <h3 class="mt-0 text-center" style="color: darkred !important;">Offer ends: {{countDown.days + "d " + countDown.hours + "h "
      + countDown.minutes + "m " + countDown.seconds + "s "}} </h3>
    </div>
  </div>
</div>
-->

<div class="container my-5">
  <div class="card mx-auto" style="max-width: 400px;">
    <div class="card-body text-center">
      <h5 class="card-title">Special Discount Offer</h5>
      <p class="card-text">Use Code: <strong>OA50</strong> for 50% off!</p>
      <div class="row gx-2 justify-content-center" id="countdown">
        <div class="col-3">
          <span id="days" class="countdown-number">{{ countDown.days }}</span> Days
        </div>
        <div class="col-3">
          <span id="hours" class="countdown-number">{{ countDown.hours }}</span> Hours
        </div>
        <div class="col-3">
          <span id="minutes" class="countdown-number">{{ countDown.minutes }}</span> Minutes
        </div>
        <div class="col-3">
          <span id="seconds" class="countdown-number">{{ countDown.seconds }}</span> Seconds
        </div>
      </div>
    </div>
  </div>
</div>
