import {environment} from '../../../environments/environment';
import {BaseStoreService} from '@core/abstract/store.abstract';
import {MockSharedService, MockStoreService} from '@mock/services';
import {MockErrorService} from '@mock/services/mock.error.service';
import {StoreService} from '@core/services/store';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from '@core/services/local-storage';
import {Mode} from '@core/enums';


const storeServiceFactory = (
  localStorageService: LocalStorageService,
  mockErrorService: MockErrorService,
  http: HttpClient,
  mockSharedService: MockSharedService,
) => {
  if (environment.mode === Mode.Mock)
    return new MockStoreService(http, mockErrorService, mockSharedService, localStorageService);
  else
    return new StoreService(localStorageService, http);

};


export const StoreServiceProvider = {
  provide: BaseStoreService,
  useFactory: storeServiceFactory,
  deps: [
    LocalStorageService,
    MockErrorService,
    HttpClient,
    MockSharedService,
  ]
};
