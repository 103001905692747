import {Injectable} from '@angular/core';
import {IOffer} from '@core/model';
import {HttpClient} from '@angular/common/http';
import {LoggingService} from './logging.service';
import {environment} from 'environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CareerService {

  constructor(private http: HttpClient, private loggingService: LoggingService) {

  }

  /**
   * Returns all offers from the api
   */
  getOffers(): Observable<IOffer[]> {
    return this.http.get<IOffer[]>(environment.urls.career.base).pipe(
      catchError((err) => {
        return of([]);
      }));
  }

  /**
   * Returns the offer associated to the given id
   * @param id the offer id
   */
  getOffer(id: number): Observable<IOffer> {
    return this.http.get<IOffer>(`${environment.urls.career.base}/${id}`);

  }

}
