import {HttpClient} from '@angular/common/http';
import {of, throwError} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, tap} from 'rxjs/operators';
import {LocalStorageService} from '@core/services/local-storage';
import {BaseStoreService} from '@core/abstract/store.abstract';
import {
  CheckoutFile,
  ICheckoutSessionLink,
  IEndTrialResponse,
  IHoliday,
  IPaymentIntent,
  IPromoCode,
  IServiceCheckoutRequest,
  IServiceCheckoutResponse,
  IServiceOrder,
} from '@core/model';
import {environment} from '../../../../environments/environment';

import {IServerOptions} from '@core/model/serverOptions';
import {isDevMode} from '@angular/core';
import {LocalStorageKey, Time} from '@core/enums';


export class StoreService extends BaseStoreService {


  constructor(private localStorageService: LocalStorageService,
              private http: HttpClient,
  ) {
    super();
  }


  createSubscriptionCheckoutSession(priceId: string): Observable<ICheckoutSessionLink> {
    const body = {
      priceId
    };
    return this.http.post<ICheckoutSessionLink>(`${environment.urls.store.base}/${environment.urls.store.subscriptions.base}`, body).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getServerOptions(): Observable<IServerOptions> {

    const options: string =
      this.localStorageService.getLocalStorageItem<string>(LocalStorageKey.STORE_SERVER_OPTIONS);

    if (!options)
      return this.http.get<IServerOptions>(`${environment.urls.store.base}/${environment.urls.store.options}`).pipe(
        tap(async receivedServerOptions => {
          await this.localStorageService.saveLocalStorageItem(LocalStorageKey.STORE_SERVER_OPTIONS,
            JSON.stringify(receivedServerOptions), 5 * Time.Minute);
        }),
        catchError(err => {
          return throwError(err);
        }));

    const storeServerOptions = JSON.parse(options);
    return of(storeServerOptions);
  }

  reportFileUpload(orderId: string): Observable<IServiceOrder> {
    return this.http.post<IServiceOrder>(`${environment.urls.store.base}/${environment.urls.store.checkout.base}/${orderId}/uploaded`, null)
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  endSubscriptionTrial(subscriptionId: string): Observable<IEndTrialResponse> {
    return this.http.post<IEndTrialResponse>(`${environment.urls.store.base}/${environment.urls.store.subscriptions.base}/${subscriptionId}/endtrial`, null).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  createProductCheckoutSession(priceId: string): Observable<ICheckoutSessionLink> {
    const body = {
      priceId
    };
    return this.http.post<ICheckoutSessionLink>(`${environment.urls.store.base}/${environment.urls.store.checkout.product}`, body).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  createServiceCheckoutSession(email: string, guestAccount: boolean, serviceName: string, files: CheckoutFile[],
                               parameters: Map<string, string>, paymentIntentId?: string):
    Observable<IServiceCheckoutResponse> {
    const body: IServiceCheckoutRequest = {
      email,
      guestAccount,
      files,
      promotions: false,
      paymentIntentId,
    };

    if (isDevMode())
      console.log(parameters);
    let url = `${environment.urls.store.base}/${environment.urls.store.serviceOrders.base}?product=${serviceName}`;

    // Append parameters to query
    // tslint:disable-next-line:forin
    parameters.forEach((value: string, key: string) => {
      url = `${url}&${key}=${value}`;
    });


    return this.http.post<IServiceCheckoutResponse>(url, body).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  createPaymentIntent(email: string, requiredCredits: number, promoCode?: string): Observable<IPaymentIntent> {
    const body: IPaymentIntent = {
      email,
      requiredCredits,
      promoCode: promoCode ?? ''
    };

    return this.http.post<IPaymentIntent>(`${environment.urls.store.base}/${environment.urls.store.checkout.paymentIntent}`, body).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getPromoCode(promoCode: string): Observable<IPromoCode> {
    return this.http.get<IPromoCode>(`${environment.urls.store.base}/${environment.urls.store.checkout.promoCode}?promoCode=${promoCode}`)
      .pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getActiveHoliday(): Observable<IHoliday> {
    return this.http.get<IHoliday>(`${environment.urls.store.base}/${environment.urls.store.holidays}`)
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

}
