import {Injectable, OnDestroy} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

/**
 * ToastService
 */
@Injectable({
  providedIn: 'root',
})
export class ToastService implements OnDestroy {

  /**
   * Returns the new User Login Value
   */
  public showToastSubscription: Subject<string> = new Subject<string>();

  private subscriptions$: Subscription[] = [];

  /**
   * ToastService Constructor
   */
  constructor() {
    this.subscriptions$.push(this.showToastSubscription.subscribe((message: string) => {
      this.showToast(message);
    }));
  }

  ngOnDestroy() {
    this.subscriptions$.forEach(x => x.unsubscribe());
  }

  /**
   * Displays a toast at the bottom of the Page
   * @param message
   */
  showToast(message: string): any {
    const x = document.getElementById('snackbar');
    x.innerHTML = message;
    x.className = 'snackbar snackbar-show';
    setTimeout(() => {
      x.className = 'snackbar';
    }, 3000);
  }
}
