import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CareerService } from '@core/services/career.service';
import {IOffer} from '@core/model';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class CareerResolver implements Resolve<IOffer[]> {

    constructor(private careerService: CareerService) {
    }

     resolve(): Observable<IOffer[]> {
        return  this.careerService.getOffers();
    }
}
