import {Injectable, isDevMode} from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {BaseStoreService} from '@core/abstract/store.abstract';
import {IServerOptions} from '@core/model/serverOptions';
import {IServerOptionsResolved} from '@core/model/HTTP/Resolver/store';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreServerOptionsResolver implements Resolve<IServerOptionsResolved> {

  constructor(private baseStoreService: BaseStoreService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IServerOptionsResolved> {
    return this.baseStoreService.getServerOptions().pipe(
      map((serverOptions: IServerOptions) => ({serverOptions} as IServerOptionsResolved)),
        catchError(err => {
          return of({
            serverOptions: {
              maintenance: true
            },
            error: err
          } as IServerOptionsResolved);
        })
    );
  }
}
