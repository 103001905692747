import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {LocalStorageService} from '@core/services/local-storage';
import {Router} from '@angular/router';
import {AnalyticsService} from '@core/services/analytics.service';
import {GaAction, GaCategory} from '@core/enums';
import {IAccount} from '@core/model';
import {ISignInData, ISignUpData} from '@core/model/HTTP/Requests';
import {UrlService} from '@core/services/urlService';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseAuthenticationService {

  protected constructor(protected localStorageService: LocalStorageService,
                        protected router: Router,
                        protected analyticsService: AnalyticsService,
                        protected urlService: UrlService) {
  }

  /**
   * Returns the new User Login Value
   */
  public userSignedIn: Subject<IAccount> = new Subject<IAccount>();

  /**
   * Being fired when sign in is being triggered and processing
   */
  public signInProcessing: Subject<boolean> = new Subject<boolean>();

  /**
   * Logout Function
   */
  signOut(): void {
    this.localStorageService.clearLocalStorage();
    this.userSignedIn.next(null);
    this.urlService.redirectUrl = null;
    this.router.navigate(['authentication/signin']).then();

    this.analyticsService.event(GaAction.SIGN_OUT, GaCategory.CustomerAcquisition, '', 1, true);
    return;
  }

  /**
   * Sends a signup request to the account server
   * @param signUpData the signup Request
   */
  abstract signUp(signUpData: ISignUpData): Observable<IAccount>;

  /**
   * Sends Http Request to Server and routes the server response
   * @param {ISignInData} signInData
   * @return {Observable<IAccount>}
   */
  abstract signIn(signInData: ISignInData): Observable<IAccount>;
}
