import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSortModule} from '@angular/material/sort';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTreeModule} from '@angular/material/tree';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {LoggingService, SharedService, ShippingService, ToastService, YoutubeService} from '@core/services';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CareerService} from '@core/services/career.service';
import {MockCareerService, MockSharedService, MockTestService} from '@mock/services/';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {AnalyticsService} from '@core/services/analytics.service';
import {CachingService} from '@core/services/HTTP/cache.service';
import {CachingInterceptor} from '@core/services/HTTP/caching.interceptor';
import {AccountPanelOrdersResolver, AccountPanelServiceOrderResolver, AccountPanelSettingsResolver, CareerResolver} from '@core/resolver';
import {AccountPanelResolver} from '@core/resolver/account-panel/account-panel.resolver';
import {LanguageService} from '@core/services/language.service';
import {MockErrorService} from '@mock/services/mock.error.service';
import {LocalStorageService} from '@core/services/local-storage';
import {AccountServiceProvider} from '@core/providers/account.service.provider';
import {StoreServiceProvider} from '@core/providers/store.service.provider';
import {AuthenticationServiceProvider} from '@core/providers/authentication.service.provider';
import {NewsletterService} from '@core/services/newsletter/newsletter.service';
import {PasswordMatchValidatorDirective} from '@core/validators/password-match.validator';
import {FullNameValidatorDirective} from '@core/validators/full-name.validator';
import {EmailVerificationModule} from '@modules/email-verification/email-verification.module';
import {environment} from '../environments/environment';
// import {RECAPTCHA_V3_SITE_KEY, RecaptchaSettings, RecaptchaV3Module} from 'ptcha';
import {UrlService} from '@core/services/urlService';
import {StoreServerOptionsResolver} from '@core/resolver/store/server-options.resolver';
import {OrdersService} from '@core/services/account/orders.service';
import {ServiceWorkerModule} from '@angular/service-worker';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireMessagingModule} from '@angular/fire/compat/messaging';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {FirebaseService} from '@core/services/firebase.service';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {AdminGuard, RedirectGuard, SignedInGuard} from '@core/guards';
import {PodcastsEpisodeService, PodcastsShowService} from '@core/services/podcasts';
import {ReCaptchaService} from '@core/services/reCaptcha.service';
import {ClipboardModule} from 'ngx-clipboard';
import {AccountServerOptionsResolver} from '@core/resolver/account-panel/server-options.resolver';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SameEmailValidatorDirective} from '@core/validators/same-email.validator';
import {UploadToolService} from '@core/services/upload-tool.service';
import {UiModule} from '@modules/shared/ui/ui.module';
import {ActiveHolidayResolver} from '@core/resolver/store/active-holiday.resolver';
import {SoftwareService} from '@core/services/software.service';

@NgModule({
  declarations: [
    AppComponent,
    PasswordMatchValidatorDirective,
    SameEmailValidatorDirective,
    FullNameValidatorDirective,
  ],
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    FormsModule,
    RouterModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    NgxGoogleAnalyticsModule.forRoot(environment.analyticsKey),
    NgxGoogleAnalyticsRouterModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    LoadingBarHttpClientModule,
    UiModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en-US'
    }),
    EmailVerificationModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterceptor,
      multi: true
    },
    /* {
       provide: RECAPTCHA_V3_SITE_KEY,
       useValue: {siteKey: environment.reCaptcha.v3} as RecaptchaSettings,
     }, */

    {provide: LOCALE_ID, useFactory: getCulture},
    AccountServiceProvider,
    StoreServiceProvider,
    AuthenticationServiceProvider,
    LocalStorageService,
    SharedService,
    LoggingService,
    ToastService,
    CareerService,
    CareerResolver,
    ShippingService,
    AccountPanelOrdersResolver,
    AdminGuard,
    SignedInGuard,
    AccountPanelSettingsResolver,
    AccountPanelResolver,
    AnalyticsService,
    CachingService,
    MockSharedService,
    MockCareerService,
    SharedService,
    MockTestService,
    LanguageService,
    MockErrorService,
    NewsletterService,
    OrdersService,
    UrlService,
    AccountServerOptionsResolver,
    AccountPanelServiceOrderResolver,
    AccountPanelServiceOrderResolver,
    StoreServerOptionsResolver,
    ActiveHolidayResolver,
    FirebaseService,
    RedirectGuard,
    PodcastsShowService,
    PodcastsEpisodeService,
    YoutubeService,
    // RecaptchaV3Module,
    ReCaptchaService,
    ClipboardModule,
    UploadToolService,
    SoftwareService,
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function getCulture() {
  const userLang = navigator.language;
  if (!userLang)
    return 'en-US';
  return userLang;
}


