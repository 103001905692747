import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {IShow} from '@core/model/podcasts';
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PodcastsShowService {

  constructor(private http: HttpClient) {
  }

  /**
   * Get all shows for the given customer. Right now, the customer is extracted from the session.
   */
  getShowsForCustomer(): Observable<IShow[]> {
    return this.http.post<IShow[]>(`${environment.urls.podcasts.base}/${environment.urls.podcasts.shows}`, null).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  /**
   * Adds a new show to the customer
   * @param show
   */
  addNewShow(show: IShow): Observable<IShow> {
    return this.http.post<IShow>(`${environment.urls.podcasts.base}/${environment.urls.podcasts.shows}`, show).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  /**
   * Updates the given show
   * @param show
   */
  updateShow(show: IShow): Observable<IShow> {
    return this.http.put<IShow>(`${environment.urls.podcasts.base}/${environment.urls.podcasts.shows}/${show.id}`, show).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  /**
   * Deletes the given show
   * @param showId
   */
  deleteShow(showId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.urls.podcasts.base}/${environment.urls.podcasts.shows}/${showId}`).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  /**
   * Grants access to another user by email
   * @param customerEmail
   * @param showId
   */
  addCustomerToShow(customerEmail: string, showId: string): Observable<boolean> {
    return this.http.post<boolean>(`${environment.urls.podcasts.base}/${environment.urls.podcasts.shows}/${showId}/access`, null).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

}
