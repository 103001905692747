// The role ids
export enum RoleIds {
  User = 0,
  Employee = 1,
  Moderator = 2,
  Administrator = 3,
}

// The role names
export enum Roles {
  User = 'User',
  Employee = 'Employee',
  Moderator = 'Moderator',
  Administrator = 'Administrator',
}


export enum RecoveryOptions {
  Email = 0,
  Password = 1,
}
