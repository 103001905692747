import {NgModule} from '@angular/core';
import {UserLanguagePipe} from './language.pipe';
import {RolePipe} from './role.pipe';
import {OrderSizePipe} from '@core/pipes/order-size.pipe';
import {FileSizePipe} from '@core/pipes/filesize.pipe';
import {FileCodecPipe} from '@core/pipes/filecodec.pipe';
import {OrderStatusIconPipe} from './order-status-icon.pipe';
import {OrderStatusTooltipPipe} from '@core/pipes/order-status-tooltip.pipe';
import {SubscriptionStatusPipe} from '@core/pipes/subscription-status.pipe';
import {OrderServiceNamePipe} from '@core/pipes/order-service-name.pipe';


@NgModule({
  declarations: [
    UserLanguagePipe,
    RolePipe,
    OrderSizePipe,
    FileSizePipe,
    FileCodecPipe,
    OrderStatusIconPipe,
    OrderStatusTooltipPipe,
    SubscriptionStatusPipe,
    OrderServiceNamePipe
  ],
  exports: [
    UserLanguagePipe,
    RolePipe,
    OrderSizePipe,
    FileSizePipe,
    FileCodecPipe,
    OrderStatusIconPipe,
    OrderStatusTooltipPipe,
    SubscriptionStatusPipe,
    OrderServiceNamePipe
  ]
})
export class PipesModule {
}
