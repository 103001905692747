/**
 * Do not change the names in here. They are used in the backend.
 */
export enum OAServices {
  MidiReplay = 'MidiReplay',
  AutoSlicer = 'AutoSlicer',
  BatchNormalizer = 'BatchNormalizer',
  SoundSeparator = 'SoundSeparator',
  FileConverter = 'FileConverter',
  BeatEstimator = 'BeatEstimator',
  ComingSoon = 'ComingSoon',
}

export enum OAServiceCredits {
  StemIsolator = 1,
  AutoSlicer = 1,
  BatchNormalization = 1,
  FileConverter = 1,
}

