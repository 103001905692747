import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from '../../../environments/environment';
import {catchError, timeout} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReCaptchaService {

  constructor(private http: HttpClient) {
  }

  /**
   * Checks if the token is a valid request or not
   * @param token
   */
  isValidRequest(token: string): Observable<any> {
    return this.http.get<any>(`${environment.urls.accounts.base}/${environment.urls.accounts.reCaptcha}/${token}`).pipe(
      timeout(30000),
      catchError(err => {
        return throwError(err);
      }));
  }

}
