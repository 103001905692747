import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {IOrder, IUploadContainerSasTokenDto} from '@core/model';
import {HttpClient} from '@angular/common/http';
import {catchError, timeout} from 'rxjs/operators';
import {Subject, throwError} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class OrdersService {

  /**
   *  Is being fired when a notification is being received in the foreground
   */
  orderCompletedNotification: Subject<void> = new Subject<void>();

  /**
   * Used to reset the badge on the account panel icon in the navbar
   */
  resetOrderCompletionCount: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient) {

  }

  /**
   * Requests reupload for a given service-order
   * @param orderId
   */
  requestReUploadForOrder(orderId: string): Observable<IUploadContainerSasTokenDto> {
    return this.http.post<IUploadContainerSasTokenDto>(`${environment.urls.store.base}/${environment.urls.store.serviceOrders}/${orderId}/reupload`, null).pipe(
      timeout(30000),
      catchError(err => {
        return throwError(err);
      }));
  }


}
