import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {LocalStorageService} from '@core/services/local-storage';
import {Router} from '@angular/router';
import {throwError} from 'rxjs';
import {AnalyticsService} from '../analytics.service';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, map} from 'rxjs/operators';
import {BaseAuthenticationService} from '@core/abstract/authentication.abtract';
import {ISignInData, ISignUpData} from '@core/model/HTTP/Requests';
import {IAccount} from '@core/model';
import {GaAction, GaCategory, GaValue} from '@core/enums';
import {isDevMode} from '@angular/core';
import {UrlService} from '@core/services/urlService';

export class AuthenticationService extends BaseAuthenticationService {


  constructor(
    private http: HttpClient,
    protected localStorageService: LocalStorageService,
    protected router: Router,
    protected analyticsService: AnalyticsService,
    protected urlService: UrlService) {
    super(localStorageService, router, analyticsService, urlService);
  }


  /**
   * Sends a signup request to the account server
   * @param signUpData the signup Request
   */
  signUp(signUpData: ISignUpData): Observable<IAccount> {
    signUpData.password = btoa(signUpData.password);
    signUpData.language = Number(signUpData.language);

    if (isDevMode())
      console.log(signUpData);

    return this.http.post(`${environment.urls.accounts.base}/${environment.urls.accounts.authentication.signUp}`, signUpData).pipe(
      map((account: IAccount) => {
        this.analyticsService.event(GaAction.SIGN_UP, GaCategory.CustomerAcquisition, '', GaValue.Medium, true);
        return account;
      }),
      catchError((err) => {
        return throwError(err);
      }));
  }

  /**
   * Sends Http Request to Server and routes the server response
   * @param {ISignInData} signInData
   * @return {Observable<IAccount>}
   */
  signIn(signInData: ISignInData): Observable<IAccount> {
    const password64 = btoa(unescape(signInData.password));
    const body: ISignInData = {
      email: signInData.email,
      password: password64,
    };


    return this.http.post<IAccount>(`${environment.urls.accounts.base}/${environment.urls.accounts.authentication.signIn}`, body).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
