import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {Subscription} from 'rxjs';
import {BaseAuthenticationService} from '@core/abstract/authentication.abtract';
import {BaseAccountService} from '@core/abstract/account.abtract';
import {IAccount} from '@core/model';
import * as $ from 'jquery';
import {OrdersService} from '@core/services/account/orders.service';
import {Router, RouterEvent} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  private subscription$: Subscription[] = [];
  public account: IAccount;
  badgeCount = 0;

  constructor(private ordersService: OrdersService,
              private baseAccountService: BaseAccountService,
              private baseAuthenticationService: BaseAuthenticationService,
              private router: Router,
              @Inject(PLATFORM_ID) private platformId: object) {
    this.subscription$.push(this.baseAuthenticationService.userSignedIn.subscribe((account: IAccount) => {
      this.account = account;
    }));

    this.subscription$.push(this.ordersService.orderCompletedNotification.subscribe(() => {
      if (this.account)
        this.badgeCount += 1;
    }));

    this.subscription$.push(this.ordersService.resetOrderCompletionCount.subscribe(() => {
      this.badgeCount = null;
    }));

    this.subscription$.push(this.router.events.subscribe((event: RouterEvent) => {
      this.closeMenu();
    }));
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      $(window).scroll(function() {
        $('#navbar').toggleClass('scrolled', $(this).scrollTop() > 50);
      });
      this.account = this.baseAccountService.getAccount();
    }
  }

  ngOnDestroy(): void {
    this.subscription$.forEach(subscription => subscription.unsubscribe());
  }

  signOut(): void {
    this.baseAuthenticationService.signOut();
  }

  closeMenu() {
    if (isPlatformBrowser(this.platformId)) {
      $('#hamburger-menu').addClass('collapsed');
      $('#navbarSupportedContent').removeClass('show');

    }

  }

}
