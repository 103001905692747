import {HttpClient} from '@angular/common/http';
import {Injectable, isDevMode} from '@angular/core';
import {LocalStorageService} from '@core/services/local-storage';
import {IContactAddress, IOrderShippingAddress} from '@core/model';


@Injectable({
  providedIn: 'root',
})
export class ShippingService {

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {

  }


  /**
   * Validates the address provided by the user
   * @param shippingAddress the shipping address
   */
  async validateShippingAddress(shippingAddress: IOrderShippingAddress | IContactAddress): Promise<boolean> {
    if (!shippingAddress.address1 || !shippingAddress.city || !shippingAddress.country || !shippingAddress.email ||
      !shippingAddress.firstName || !shippingAddress.lastName ||
      !shippingAddress.zipCode) {
      if (isDevMode()) {
        console.log(`Missing a property in contactDetails:`);
        console.log(shippingAddress);
      }


      return false;
    }

    if (shippingAddress.zipCode === 0)
      return false;

    if (shippingAddress.country === 'US')
      if (!shippingAddress.state)
        if (isDevMode()) {
          console.log('Country is US and state is empty');
          return false;
        }


    // TODO: Add usps/ups validation
    return true;
  }

  /**
   * Requests the estimated shipping costs for the package
   * @param shippingAddress the shipping address
   */
  async getEstimatedShippingCost(shippingAddress: IOrderShippingAddress): Promise<number> {
    return 0.00;
  }

}
