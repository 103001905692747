import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class YoutubeService {

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
  }

  /**
   * Asynchronously load the YouTube api
   */
  loadYoutubeApi(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        if (isPlatformBrowser(this.platformId)) {
          const tag = document.createElement('script');
          tag.src = 'https://www.youtube.com/iframe_api';
          document.body.appendChild(tag);
          resolve();
        }

        resolve();
      } catch (err) {
        reject();
      }


    });
  }

}
