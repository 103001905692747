import { Injectable, isDevMode } from '@angular/core';

/**
 * The Loggerservice
 */
@Injectable({
  providedIn: 'root',
})
export class LoggingService {

    /**
     * Constructor for Logger Service
     */
    constructor() {

    }

    /**
     * Loglevel for the Logger
     */
    logNumber: LogLevel;

    /**
     * The devlog function
     * @param msg
     * @param logLevel
     */
    public devLog(msg: any, logLevel: number): void {
        if (isDevMode()) {
            this.logNumber = logLevel;
            switch (this.logNumber) {
                case 0:
                    console.log('Error: ' + msg);
                    break;
                case 1:
                    console.log('Warning: ' + msg);
                    break;
                case 2:
                    console.log('Info: ' + msg);
                    break;
                case 3:
                    console.log('Debug: ' + msg);
                    break;
                default:
                    console.log('Missing LogLevel: ' + msg);
            }
        }
    }

    public devJSONLog(msg: any, logLevel: number): void {
        if (isDevMode()) {
            this.logNumber = logLevel;
            switch (this.logNumber) {
                case 0:
                    console.log('Error: ' + JSON.stringify(msg));
                    break;
                case 1:
                    console.log('Warning: ' + JSON.stringify(msg));
                    break;
                case 2:
                    console.log('Info: ' + JSON.stringify(msg));
                    break;
                case 3:
                    console.log('Debug: ' + JSON.stringify(msg));
                    break;
                default:
                    console.log('Missing LogLevel: ' + JSON.stringify(msg));
            }
        }
    }
}


/**
 * LogLevel
 */
export enum LogLevel {
    Error = 0,
    Warning = 1,
    Info = 2,
    Debug = 3,
}
