import {Injectable} from '@angular/core';
import {HttpRequest, HttpResponse} from '@angular/common/http';

// 3 Minutes of cache
const maxAge = 180000;

@Injectable()
export class CachingService {

  cache = new Map();

  get(req: HttpRequest<any>): HttpResponse<any> {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);

    if (!cached)
      return null;

    const isExpired = cached.lastRead < (Date.now() - maxAge);
    if (isExpired)
      return null;

    return cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.url;
    const entry = {url, response, lastRead: Date.now()};
    this.cache.set(url, entry);

    const expired = Date.now() - maxAge;
    this.cache.forEach(expiredEntry => {
      if (expiredEntry.lastRead < expired)
        this.cache.delete(expiredEntry.url);
    });
  }
}
